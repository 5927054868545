import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { GlobalState, Invoice, OnCompletedStepProps } from '../../../../types';
import { Error } from '../../../../components/ui/Error';
import { Collapse, Grid, IconButton, StyledComponentProps } from '@material-ui/core';
import { FullScreenLoader, wait } from "@roadsync/roadsync-ui";
import { getInvoice, markInvoiceProcessing } from '../../../../actions/invoices';
import { post } from '../../../../services/api/http';
import InvoiceFinalizeContainer from '../../../../components/invoice/InvoiceFinalizeContainer';
import { Log } from '../../../../services/LoggerService';
import { isLicenseRequiredEnabled, isUseStandardRainforestButtonEnabled, isZipBillingAddressEnabled } from '../../../../services/app/company';
import CardDetailsForm, { CardDetailsFormData } from './CardDetailsForm';
import { Button } from '../../../../components/ui/Buttons';
import { submit } from 'redux-form';
import { InvoicePaths } from '../../../../services/app/paths';
import { InvoiceStatuses } from '../../../../constants/invoice';
import { getInvoice as getInvoiceFromAPI } from '../../../../services/api/invoices';
import CustomStyledYellowAlert from '../../../../components/invoice/CustomStyledYellowAlert';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import { CloseIcon } from '../../../../components/ui/Icons';
import { AuthState } from '../../../../reducers/auth';
import ShoppingBagIcon from '@material-ui/icons/LocalMall';
import { InvoiceDeclineReasons } from '../../../../constants/invoiceDeclineReasons';
import { CreditCardProcessingModal } from './CreditCardProcessingModal';
import RainforestPayment, { RainforestDecline, RainforestError } from '../../../../components/rainforest/RainforestPayment';
import InvoicePriceUpdatedModal from '../../../../components/rainforest/InvoicePriceUpdatedModal';
import { formatDollar } from '../../../../services/app/formats';
import { closeInvoicePriceUpdatedModal } from '../../../../actions/invoicePriceUpdatedModal';
import { showErrorAlert } from '../../../../actions/alerts';

interface RouteParams {
    invoiceId: string;
}

type PropsFromState = Pick<GlobalState, "appSettings" | "auth" | "invoicePriceUpdatedModal"> & {
    auth: AuthState;
}

interface Props extends OnCompletedStepProps, StyledComponentProps, PropsFromState {
    pageHeader: string;
}

export interface RainforestSessionResponse {
    data: { session_key: string };
}

export interface RainforestPayinConfigResponse {
    data: { payin_config_id: string };
}

const RainforestCardDetails: React.FC<Props> = (props: Props) => {
    const defaultErrMsg = 'Something went wrong. Please try again';
    const { onCompletedStep } = props;
    const dispatch = useDispatch();
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [sessionKey, setSessionKey] = useState('');
    const [payinConfigId, setPayinConfigId] = useState('');
    const [isRSPayBtnDisabled, setIsRSPayBtnDisabled] = useState(true);
    const [isBillingInfoRequired, setIsBillingInfoRequired] = useState<boolean | undefined>();
    const params = useParams<RouteParams>();
    const invoiceId = params.invoiceId;
    const [billingInfo, setBillingInfo] = useState<CardDetailsFormData>();
    const history = useHistory();
    const invoice: Invoice & { loading?: boolean } | undefined = useSelector((state: GlobalState) => state.invoices?.data?.[invoiceId]);
    const [initialPrice, setInitialPrice] = useState<string | undefined>();
    const [initialAmount, setInitialAmount] = useState<string | undefined>();
    const [openAmountWarning, setOpenAmountWarning] = useState(false);
    const isInvoicePriceUpdatedModalOpen = useSelector((state: GlobalState) => state.invoicePriceUpdatedModal?.isInvoicePriceUpdatedModalOpen);
    const amountMismatchInvoiceId = useSelector((state: GlobalState) => state.invoicePriceUpdatedModal?.invoiceId);
    const [isProcessingDialogOpened, setIsProcessingDialogOpened] = useState(false);
    const [isRainforestFormDisabled, setIsRainforestFormDisabled] = useState(false);
    const [isFormLoaded, setIsFormLoaded] = useState(false);
    const [isPaymentError, setIsPaymentError] = useState(false);
    const [initialPaymentError, setInitialPaymentError] = useState<string | undefined>();
    const intervalRef = useRef<any>(null);
    // a backup to prevent unwanted mismatch modals if there were multiple websocket messages (the API should send only one with current invoiceId)
    const [stopShowingMismatchModalTillReset, setStopShowingMismatchModalTillReset] = useState(true)

    const company = useSelector((state: GlobalState) =>
        "string" === typeof invoice?.company
            ? state.companies?.data?.[invoice.company]
            : undefined
    );
    const isLicenseRequired = isLicenseRequiredEnabled(company);
    const onBillingInfoSubmit = (data: CardDetailsFormData): void => {
        setBillingInfo(data);
        setIsBillingInfoRequired(false);
    }

    const submitBillingInfo = (): void => {
        try {
            setIsLoading(true);
            dispatch(submit('cardDetails'));
            handleClearInterval();
        } catch (e) {
            setError(`${(e as any)?.message || defaultErrMsg}`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleClearInterval = (): void => {
        clearInterval(intervalRef.current);
    }

    const openProcessingDialog = useCallback((): void => {
        if (!isProcessingDialogOpened) {
            setIsProcessingDialogOpened(true);
        }
    }, [isProcessingDialogOpened]);

    const closeProcessingDialog = useCallback((): void => {
        isProcessingDialogOpened && setIsProcessingDialogOpened(false);
    }, [isProcessingDialogOpened]);

    // Comparing amounts here prevents from opening the modal after Try Again was clicked.
    // The API keeps sending the message periodically
    const openMismatchAmountModal = !!isInvoicePriceUpdatedModalOpen && !!initialAmount
        && initialAmount !== invoice?.grandTotal
        && !stopShowingMismatchModalTillReset // stopShowingMismatchModalTillReset is a backup to prevent unwanted mismatch modals if there were multiple websocket messages (the API should send only one with current invoiceId)

    // Automatically show/hide processing dialog on updates received through websockets.
    // Also detect invoices which were paid for and return user to invoice list.
    useEffect(() => {
        switch (invoice?.status) {
            case InvoiceStatuses.COMPLETED.key:
                history.push(InvoicePaths.listUrl());
                break;
            case InvoiceStatuses.PROCESSING.key:
                openProcessingDialog();
                break;
            default:
                closeProcessingDialog();
                break;
        }
    }, [invoice, openProcessingDialog, closeProcessingDialog, history]);

    useEffect(() => {
        document.querySelector('#invoice-finalize-container')?.scrollIntoView({ behavior: "smooth" });

        return () => {
            // cleanup function when the component unmounts
            dispatch(closeInvoicePriceUpdatedModal())
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPayinConfig = useCallback(() => {
        setIsLoading(true);
        post<RainforestPayinConfigResponse>(`/api/v1/invoices/${invoiceId}/payin/config`, JSON.stringify({
            billingFirstName: billingInfo?.billingFirstName ?? '',
            billingLastName: billingInfo?.billingLastName ?? '',
            streetAddress: billingInfo?.streetAddress ?? '',
            city: billingInfo?.city ?? '',
            state: billingInfo?.cardState ?? '',
            zipCode: billingInfo?.zipCode ?? '',
        }))
            .then((r) => {
                setPayinConfigId(r.data.payin_config_id);
                setIsLoading(false);
            })
            .catch((e) => {
                if (e?.message === 'Payment for this invoice was already attempted. Please contact support.') {
                    dispatch(showErrorAlert(e.message));
                }
                setError(e?.message ?? defaultErrMsg);
                setIsLoading(false);
            });
    },
        [billingInfo?.billingFirstName, billingInfo?.billingLastName, billingInfo?.cardState, billingInfo?.city, billingInfo?.streetAddress, billingInfo?.zipCode, invoiceId]);

    useEffect(() => {
        // on update via websocket
        if (!!initialPrice && initialPrice !== invoice?.grandTotal) {
            setInitialPrice(invoice?.grandTotal);
            setOpenAmountWarning(true);
            getPayinConfig()
        }
        const isInvoiceFailed = invoice?.status == InvoiceStatuses.FAILED.key;
        const isNewFailure = isInvoiceFailed && invoice?.paymentError != initialPaymentError;

        if (isNewFailure && isPaymentError) {
            setInitialPaymentError(invoice?.paymentError);
            setIsPaymentError(false);
            const reason = InvoiceDeclineReasons.getByKey(invoice?.paymentError ?? '')
            setError(reason.display);
        }
        // polling back up
        // tracks if the amount was updated in another tab or on another device to show a warning and update the payin config
        const interval = setInterval(async () => {
            await dispatch<any>(getInvoice(invoiceId)).then(() => {
                if (!invoice) return;
                const refreshedPrice = invoice.grandTotal;
                if (initialPrice !== refreshedPrice) {
                    setInitialPrice(refreshedPrice);
                    setOpenAmountWarning(true);
                    getPayinConfig()
                }
            });
        }, 2000);

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceId, initialPrice, invoice, dispatch, billingInfo, isBillingInfoRequired]);

    const verifyInvoiceStatus = useCallback(async () => {

        const handleInvoiceStatusUpdates = (i?: Invoice) => {
            i?.status === InvoiceStatuses.PROCESSING.key ? openProcessingDialog() : closeProcessingDialog();
        }

        if (!!invoice?.authorizedAt || !!invoice?.deletedAt) {
            clearInterval(intervalRef.current);
            return history.push(InvoicePaths.listUrl());
        }

        handleInvoiceStatusUpdates(invoice);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoice, invoiceId, initialAmount, history]);

    useEffect(() => {
        const interval = setInterval(() => verifyInvoiceStatus(), 2000);
        intervalRef.current = interval;
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        (function () {
            const required = isZipBillingAddressEnabled(company)
            setIsBillingInfoRequired(required);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch<any>(getInvoice(invoiceId)).then(async () => {
            if (!invoice) return;
            const initialGrandTotal = invoice?.grandTotal;
            if (!initialAmount) setInitialAmount(initialGrandTotal); // it should keep the value for testing purposes even when initialPrice gets updated
            if (!initialPrice) setInitialPrice(initialGrandTotal);
        });

        post<RainforestSessionResponse>(`/api/v1/invoices/${invoiceId}/payin/session`)
            .then((r) => setSessionKey(r.data.session_key))
            .catch((e) => setError(e?.message ?? defaultErrMsg));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceId, dispatch, initialAmount]);

    useEffect(() => {
        if (isBillingInfoRequired === undefined || isBillingInfoRequired || !sessionKey) {
            return;
        }

        getPayinConfig();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceId, dispatch, billingInfo, isBillingInfoRequired, sessionKey]);

    const invoiceUpdatedAmount = useCallback(() => {
        if (amountMismatchInvoiceId === invoice?.id) {
            setIsRainforestFormDisabled(true);
            closeProcessingDialog();
            setIsFormLoaded(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amountMismatchInvoiceId]);

    useEffect(() => {
        if (!openMismatchAmountModal) return;

        invoiceUpdatedAmount();

    }, [openMismatchAmountModal, invoiceUpdatedAmount]);

    const onRainforestPaymentLoad = useCallback((loaded: boolean): void => {
        if (loaded) {
            setIsFormLoaded(true);
            return;
        }
        setError('Something went wrong. Please reload the page');
        setIsFormLoaded(false);
    }, []);

    const onPaymentAttempted = useCallback(async (): Promise<void> => {
        await dispatch<any>(markInvoiceProcessing(invoice?.token as string));
        openProcessingDialog();
    }, []);

    const onPaymentApproved = useCallback(async (): Promise<void> => {
        try {
            openProcessingDialog();
            clearInterval(intervalRef.current);
            const maxAttempts = 10;
            let attempts = 0;
            let invoice = await getInvoiceFromAPI(invoiceId);

            while (attempts < maxAttempts
                && invoice?.status !== InvoiceStatuses.COMPLETED.key
                // on amount mismatch during payment processing - when a rainforest transaction is successful
                // with a different grand total the API should set invoice status as failed, refund the transaction
                // and send a message with the action === 'rainforest_amount_mismatch' through websocket
                // and the UI would show the InvoicePriceUpdatedModal
            ) {
                if (invoice?.status === InvoiceStatuses.FAILED.key) {
                    setStopShowingMismatchModalTillReset(false); // a backup to prevent unwanted mismatch modals if there were multiple websocket messages (the API should send only one with current invoiceId)
                    // if there is no mismatch, but still a failed invoice check test env for invoive with the same invoiceNumber and its status
                    // at this point you might have an infinite spinner in the UI due to conflict
                    return
                }

                await wait(3000);
                invoice = await getInvoiceFromAPI(invoiceId);
                attempts++;
            }

            // happy path
            if (invoice?.status === InvoiceStatuses.COMPLETED.key) {
                history.push(InvoicePaths.listUrl());
            }
        } catch (e) {
            setError(`${(e as any)?.message || defaultErrMsg}`);
        } finally {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceId, history, initialAmount]);

    const onPaymentDeclined = useCallback(async (e: CustomEvent<RainforestDecline[]>): Promise<void> => {
        setInitialPaymentError(undefined);
        setIsPaymentError(true);
        setIsFormLoaded(true);
        setIsRSPayBtnDisabled(false);
        setIsBillingInfoRequired(true);
        setIsLoading(false);
        closeProcessingDialog();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onPaymentError = useCallback((e: CustomEvent<RainforestError[]>): void => {
        setError(e.detail.map((error) => error.message).join(' ,') ?? defaultErrMsg);
        setIsBillingInfoRequired(true);
        setIsLoading(false);
        setIsFormLoaded(true);
    }, []);

    const onPaymentValid = useCallback((): void => setIsRSPayBtnDisabled(false), []);

    const onPaymentInvalid = useCallback((): void => setIsRSPayBtnDisabled(true), []);

    const onPaymentSubmit = async () => {
        setIsRSPayBtnDisabled(true);
        setIsLoading(true);
        const form = document.querySelector('rainforest-payment');
        try {
            await dispatch<any>(markInvoiceProcessing(invoice?.token as string));
        } catch (e) {
            setError(`${(e as any)?.message || defaultErrMsg}`);
            Log.warn('Mark invoice processing error', e);
            setIsRSPayBtnDisabled(false);
        } finally {
            (form as HTMLFormElement)?.submit();
        }
    }

    const handleCancel = (): void => {
        if (!stopShowingMismatchModalTillReset) setStopShowingMismatchModalTillReset(true) // a backup to prevent unwanted mismatch modals if there were multiple websocket messages (the API should send only one with current invoiceId)
        dispatch(closeInvoicePriceUpdatedModal());
        history.push(InvoicePaths.listUrl());
    }

    const handleTryAgain = (): void => {
        getPayinConfig();
        setIsBillingInfoRequired(false);
        dispatch(closeInvoicePriceUpdatedModal());
        setIsFormLoaded(true);
        setIsRainforestFormDisabled(false);
        setInitialAmount(invoice?.grandTotal);
        if (!stopShowingMismatchModalTillReset) setStopShowingMismatchModalTillReset(true) // a backup to prevent unwanted mismatch modals if there were multiple websocket messages (the API should send only one with current invoiceId)

    }

    const useStandardRainforestButton: boolean = isUseStandardRainforestButtonEnabled(company);

    return (
        <InvoiceFinalizeContainer hideSubmitButton onCompletedStep={onCompletedStep} formName="rainforestCardDetails" handleSubmit={(_) => { }}>
            <Grid container direction="column" spacing={2}>
                <FullScreenLoader show={isLoading && !isProcessingDialogOpened} />
                <CreditCardProcessingModal show={isProcessingDialogOpened} />
                {error && <Grid item><Error error={error} /></Grid>}
                {isBillingInfoRequired &&
                    <>
                        <Grid item>
                            <CardDetailsForm
                                licenseRequired={isLicenseRequired}
                                onSubmit={onBillingInfoSubmit} />
                        </Grid>
                        <Grid item>
                            <Button color="primary" size="large" data-testid="billing-info-submit-btn" onClick={submitBillingInfo}>Save &amp; Continue</Button>
                        </Grid>
                    </>
                }
                {!isBillingInfoRequired && !isRainforestFormDisabled &&
                    <Collapse in={openAmountWarning}>
                        <Grid item>
                            <CustomStyledYellowAlert
                                icon={<StarOutlineIcon fontSize="inherit" />}
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setOpenAmountWarning(false);
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            >
                                The amount has been recently updated.
                            </CustomStyledYellowAlert>
                        </Grid>
                    </Collapse>
                }

                {sessionKey && payinConfigId && !isBillingInfoRequired && !isRainforestFormDisabled &&
                    <Grid item data-testid="rainforest-pay-form-container" style={{ display: 'flex', justifyContent: 'center' }}>
                        <RainforestPayment
                            sessionKey={sessionKey}
                            payinConfigId={payinConfigId}
                            hideButton={!useStandardRainforestButton}
                            onLoad={onRainforestPaymentLoad}
                            onPaymentAttempted={onPaymentAttempted}
                            onPaymentApproved={onPaymentApproved}
                            onPaymentDeclined={onPaymentDeclined}
                            onPaymentError={onPaymentError}
                            onPaymentInvalid={onPaymentInvalid}
                            onPaymentValid={onPaymentValid}
                        />
                    </Grid>
                }
                <InvoicePriceUpdatedModal
                    open={openMismatchAmountModal}
                    handleCancel={handleCancel}
                    handleTryAgain={handleTryAgain}
                    initialAmount={formatDollar(initialAmount)}
                    updatedAmount={formatDollar(invoice?.grandTotal)}
                />
                {sessionKey && payinConfigId && !isBillingInfoRequired && isFormLoaded && !useStandardRainforestButton &&
                    <div className=" text-center ">
                        <button
                            style={{ borderRadius: '90rem', width: '80%', backgroundColor: isRSPayBtnDisabled ? '' : '#212121' }}
                            className="btn btn-dark btn-full"
                            type="submit"
                            id="submit"
                            data-testid="submit"
                            disabled={isRSPayBtnDisabled}
                            onClick={onPaymentSubmit}>
                            <span>
                                <ShoppingBagIcon style={{ fontSize: 'medium', verticalAlign: 'middle', marginRight: '5px', marginBottom: '3px' }} />
                                Pay ${parseFloat(invoice?.grandTotal || "0").toFixed(2)}
                            </span>
                        </button>
                    </div>
                }
            </Grid>
        </InvoiceFinalizeContainer>
    );
}

export default memo(RainforestCardDetails);
